
import { ref, defineComponent } from 'vue'
import * as api from '../utils/api'
import Hospital from '../components/Hospital.vue'
import Header from '../components/menu/Public.vue'
import { useDialog } from 'naive-ui'
import { IAPIReturnWithReview } from '../../interfaces/hospital'
import data from '../sitemap'

export default defineComponent({
	components: {
		Hospital,
		Header,
	},
	data() {
		const dialog = useDialog()
		const errorDialog = (e: any, onPositiveClick: Function) =>
			dialog.error({
				title: 'Error',
				content: e.message || '不明なエラー',
				closable: false,
				positiveText: 'OK',
				onPositiveClick: () => onPositiveClick(),
			})
		return {
			errorDialog,
			loading: false,
			applys: [],
		}
	},
	mounted: async function () {
		try {
			this.applys = data as any
		} catch (e: any) {
			this.loading = false
			this.errorDialog(e, () => true)
		}
	},
	methods: {},
})
